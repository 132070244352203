<template>
  <div class="notification-item" @click="$emit('click', notification)">
    <!-- Icon -->
    <div class="notification-item__icon">
      <span v-if="isLoading" class="notification-item__icon-wrapper"></span>
      <span v-else-if="!$slots.icon" class="notification-item__icon-wrapper">
        <i class="fas fa-bell"></i>
      </span>
      <slot name="icon"></slot>
    </div>
    <!-- Content -->
    <div class="notification-item__content">
      <div v-if="isLoading">
        <span class="skeleton d-inline-block w-50"></span>
        <span class="skeleton d-inline-block ml-2 w-25"></span>
        <div class="mt-2">
          <span class="skeleton d-block w-100 mb-1"></span>
          <span class="skeleton d-block w-75"></span>
        </div>
      </div>
      <div v-else>
        <!-- Title section -->
        <div>
          <!-- Title -->
          <span class="notification-item__title">
            {{ notification.title }}
          </span>
          <!-- Redirect Indicator -->
          <span
            v-if="hasRedirect"
            class="notification-item__redirect-indicator pl-1"
          >
            <i class="fas fa-external-link-alt"></i>
          </span>
        </div>
        <!-- Body -->
        <span class="notification-item__body">
          {{ notification.body }}
        </span>
        <span class="d-block notification-item__time">
          {{ notification.createdAt }}
        </span>
      </div>
    </div>

    <!-- Read Indicator -->
    <div class="notification-item__read-indicator">
      <span
        :class="['indicator', { read: notification.seen, loading: isLoading }]"
      ></span>
    </div>
  </div>
</template>

<script>
import "@/assets/GlobalStyling/static-css/skeleton.scss";

export default {
  components: {},
  mixins: [],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    notification: {
      type: Object,
      default: () => ({})
    },
    hasRedirect: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.notification-item {
  $self: notification-item;
  cursor: pointer;
  display: flex;
  padding: 14px 10px;
  border-radius: 4px;
  &:hover {
    background: #f8f8f8;
  }

  .#{$self}__icon {
    margin-right: 20px;
    width: 36px;
    height: 36px;
    min-width: 36px;

    .#{$self}__icon-wrapper {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #eee;
      color: #707070;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .#{$self}__content {
    font-size: 14px;
    flex-grow: 1;

    .#{$self}__title {
      font-weight: 500;
    }
    .#{$self}__time {
      color: #a1a1a1;
      font-weight: normal;
    }
    .#{$self}__body {
      font-size: 14px;
      color: #505050;
      font-weight: normal;
    }
  }

  .#{$self}__redirect-indicator {
    i {
      color: #a1a1a1;
    }
  }

  .#{$self}__read-indicator {
    padding-left: 10px;
    padding-right: 10px;

    .indicator {
      display: block;
      border-radius: 4px;
      width: 10px;
      height: 10px;
      background: $color-main;
      &.read {
        background: none;
      }
      &.loading {
        background: #ddd;
      }
    }
  }
}
</style>
